import logo from "./logo2.svg"
import {useState} from 'react'
import {Container, Stack, Nav, Navbar, Offcanvas, Form, Button, Modal, InputGroup, NavDropdown, FloatingLabel} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faTicket, faUserPlus, faUserGear, faSearch, faTentArrowDownToLine, faCalendarDay,  faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import Sign from './Sign.js'
import { logswitch } from './loginSlice.js'
import { useSelector, useDispatch } from 'react-redux'



export default function Element() {
  const [showSearch, setShowSearch] = useState(false);

  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  const logged = useSelector((state) => state.login.value)
  const dispatch = useDispatch()

  return (
    <>
      <Navbar key='false' bg="light" expand='false' className="mb-3" collapseOnSelect>
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand href="#">
              <img src={logo} height="30" className="d-inline align-top" alt="React Bootstrap logo" />
            </Navbar.Brand>
          </LinkContainer>
          <Nav className="ms-auto">
            <Button variant="outline-dark" onClick={handleShowSearch}><span className='d-none d-sm-inline-block'>Cerca</span> <FontAwesomeIcon fixedWidth icon={faSearch}/></Button>
            <Modal show={showSearch} onHide={handleCloseSearch} restoreFocus={false} centered>
              <Modal.Body>
                <Form>
                  <InputGroup>
                    <Form.Control placeholder="Cerca un evento o digita una città" aria-label="Digita un evento o una città" />
                    <Button type="submit" variant="outline-dark"><FontAwesomeIcon fixedWidth icon={faSearch}/></Button>
                  </InputGroup>
                </Form>
              </Modal.Body>
            </Modal>
          </Nav>
          <Navbar.Toggle className="ms-2" aria-controls={`offcanvasNavbar-expand-main`}></Navbar.Toggle>
        </Container>
        <Navbar.Offcanvas placement="end" id={`offcanvasNavbar-expand-main`} aria-labelledby={`offcanvasNavbarLabel-expand-main`} >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-main`}>
              my <img src={logo} height="30" className="d-inline align-top" alt="React Bootstrap logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {!logged?(<Sign />):<>


            <Nav className="justify-content-end flex-grow-1">
              <LinkContainer to="/pass">
                <Nav.Link><FontAwesomeIcon fixedWidth icon={faTicket} /> Pass</Nav.Link>
              </LinkContainer>
              <NavDropdown title={<><FontAwesomeIcon fixedWidth icon={faTentArrowDownToLine} /> Organizza</>}>
                <LinkContainer to="/nuovoEvento">
                  <NavDropdown.Item href="#action3"><FontAwesomeIcon fixedWidth icon={faCalendarPlus} /> Nuovo evento</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/eventiProgrammati">
                  <NavDropdown.Item href="#action3"><FontAwesomeIcon fixedWidth icon={faCalendarDay} /> Eventi programmati</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to="/account">
                <Nav.Link><FontAwesomeIcon fixedWidth icon={faUserGear} /> Configura</Nav.Link>
              </LinkContainer>
              <hr/>

              <NavDropdown title={<><FontAwesomeIcon fixedWidth icon={faCode} /> DEV shortcuts</>}>
                <LinkContainer to="/nuovoTicket">
                  <NavDropdown.Item href="#action3"><FontAwesomeIcon fixedWidth icon={faCode} /> Nuovo Ticket</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/modEv">
                  <NavDropdown.Item href="#action3"><FontAwesomeIcon fixedWidth icon={faCode} /> Eventi programmati</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
            </>}
            <Button variant="outline-primary" onClick={() => dispatch(logswitch())} size="sm"><FontAwesomeIcon fixedWidth icon={faCode} /> {logged?"as logged out":"as logged in"}</Button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}
