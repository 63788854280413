import { Container } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import Navbar from './navbar.js'

export default function Element () {
  return (
    <div>
      <Navbar />
      <Container fluid>
        <Outlet/>
      </Container>
    </div>
  );
}
