import {useState} from 'react'
import {Container, Stack, Nav, Navbar, Offcanvas, Form, Button, Modal, InputGroup, NavDropdown, FloatingLabel} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faTicket, faUserPlus, faUserGear, faSearch, faTentArrowDownToLine, faCalendarDay,  faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import logo from "./logo2.svg"



export default function Element() {
  const [showSearch, setShowSearch] = useState(false);

  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  return (
    <>
      <h6>
      Iscrizione a <b>EVE<sup>NT</sup></b>
      </h6>
      <Stack gap="3" className="mt-3">
        <FloatingLabel controlId="floatingNewName" label="Il tuo nome" >
          <Form.Control type="text" placeholder="Il tuo nome" />
        </FloatingLabel>
        <FloatingLabel controlId="floatingNewEmail" label="Il tuo indirizzo email" >
          <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>
        <FloatingLabel controlId="floatingNewPassword" label="Nuova password" >
          <Form.Control type="password" placeholder="Password" />
        </FloatingLabel>
        <Button variant="primary" type="submit" size="sm">Iscriviti</Button>
      </Stack>
    </>
  );
}
