//import { Col, Row } from 'react-bootstrap';

export default function Element () {
  return (
    <>
      <h1>I tuoi pass</h1>
      <h2>I tuoi pass</h2>
      <h3>I tuoi pass</h3>
      <h4>I tuoi pass</h4>
      <h5>I tuoi pass</h5>
      <h6>I tuoi pass</h6>
      <p>I tuoi pass</p>
    </>
  );
}
