import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'login',
  initialState: {
    value: false,
  },
  reducers: {
    logswitch: (state) => { state.value = !state.value }
  },
});
// Action creators are generated for each case reducer function
export default slice.reducer;
export { slice };
export const { logswitch } = slice.actions
