import {useState} from 'react'
import {Container, Fade, Tabs, Tab, Stack, Nav, Navbar, Offcanvas, Form, Button, Modal, InputGroup, NavDropdown, FloatingLabel} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faTicket, faUserPlus, faUserGear, faSearch, faTentArrowDownToLine, faCalendarDay,  faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import logo from "./logo2.svg"
import SignIn from './SignIn.js'
import SignUp from './SignUp.js'



export default function Element() {
  const [key, setKey] = useState('first');

  return (
    <>
    <Tab.Container id="left-tabs-example" activeKey={key} transition={Fade}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <SignIn />
          <hr/>
          Non hai un account? <Button variant="outline-primary" onClick={()=>setKey('second')} size="sm">Iscriviti</Button>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <SignUp />
          <hr/>
          Hai già un account? <Button variant="outline-primary" onClick={()=>setKey('first')} size="sm">Accedi</Button>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    </>
  );
}
