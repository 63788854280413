import './app.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Base from './Base.js';
import Pass from "./Pass.js";
import RimbosoTicket from "./RimbosoTicket.js";
import Account from "./Account.js";
import EventiProgrammati from "./EventiProgrammati.js";
import NuovoEvento from "./NuovoEvento.js";

import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from './counterSlice.js'


export default function Element () {
  const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch()
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Base />}>
          <Route path="pass" element={<Pass />} />
          <Route path="rimbosoTicket" element={<RimbosoTicket />} />
          <Route path="account" element={<Account />} />
          <Route path="eventiProgrammati" element={<EventiProgrammati />} />
          <Route path="nuovoEvento" element={<NuovoEvento />} />
          <Route path="*" element={<>
            <button onClick={() => dispatch(increment())}>Increment</button>
            <span>{count}</span>
            <button onClick={() => dispatch(decrement())}>Decrement</button>
          </>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
