import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => { state.value += 1 },
    decrement: (state) => { state.value -= 1 },
    incrementByAmount: (state, action) => { state.value += action.payload },
  },
});
// Action creators are generated for each case reducer function
export default slice.reducer;
export { slice };
export const { increment, decrement, incrementByAmount } = slice.actions
